import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container, Col, Row } from "react-bootstrap"

import upperright from "../images/muto1.png";
import bottomleft from "../images/muto3.jpg";

export default function Services({location}) {
  return (
    <Layout lang='hu' location={location}>
      <SEO lang='hu' title="Szolgáltatások"/>
      <Container fluid className="h-100" style={{backgroundColor: "var(--primary-color)"}}>
        <Row style={{minHeight: "60vh"}}>
          <Col className="align-self-center" >
            <Container className="text-center leistungen-text" style={{paddingTop: 40, paddingBottom: 40}}>
              <p>Egy betegség vagy egy szükséges operáció gyakran bizonytalanságérzéssel és szorongással jár együtt. 
              A személyre szabott optimális kezeléshez vagy a sikeres műtéthez elengedhetetlen, hogy legyen idő minden nyitott kérdést megválaszolni. 
              A privátrendelés bizalomteljes, nyugodt légkört biztosít az első találkozótól az utolsó kontrollvizsgálatig. 
              Magyar pácienseim számára különösen nagy segítséget jelenthet az anyanyelvi kommunikáció, illetve a diagnosztikai vagy műtéti beavatkozások gyors és problémamentes megszervezése.</p>
            </Container>
          </Col>
          <Col className="muto1" style={{backgroundImage: `url(${upperright})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}/>
        </Row>
        <Row  style={{minHeight: "60vh"}}>
          <Col className="muto2" style={{backgroundImage: `url(${bottomleft})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}/>  
          <Col className="align-self-center">
          <Container className="text-center leistungen-text" style={{paddingTop: 40, paddingBottom: 40}}>
              <p>A szükséges műtéteket az oberpullendorfi kórházban az általános betegbiztosítás által finanszírozottan végzem.</p>
              <p>Operatív tevékenységem a teljes általános sebészeti spektrumot felöleli:</p>
               <p className="leistungen-item"><b>Epehólyag/epeút</b></p>
               <p className="leistungen-item"><b>Lágyéksérv - TAPP</b></p>
               <p className="leistungen-item"><b>Köldöksérv/Hegsérv - eTEP/eMILOS</b></p>
               <p className="leistungen-item"><b>Vékonybél/vastagbél</b></p>
               <p className="leistungen-item"><b>Gyomor</b></p>
               <p className="leistungen-item"><b>Bőr/bőr alatti szövetek tumorai</b></p>
               <p className="leistungen-item"><b>Proktológia, Aranyér - DGHAL</b></p>
              <p style={{marginTop: '1rem'}}>Amennyiben lehetséges, mindig a minimál invazív technikát alkalmazom, a legmodernebb laparoszkópos módszerek és eszközök felhasználásával.</p>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
